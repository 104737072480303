import { Chip } from "@mui/material";
import { TableColumn } from "../../../components/table";
import moment from "moment";

export const AVAILABLE_COLUMN_OUT_REQ: TableColumn[] = [
  {
    id: "InitTime",
    dataIndex: "InitTime",
    header: "Request Date",
    render: (obj) => moment(obj).format("yyyy-MM-DD"),
  },
  {
    id: "SalesOrderId",
    dataIndex: "SalesOrderId",
    header: "Sales Order",
  },
  {
    id: "Type",
    dataIndex: "Type",
    header: "Type",
  },
  {
    id: "ReceiverName",
    dataIndex: "ReceiverName",
    header: "Receiver",
  },
  {
    id: "ReceiverPhone",
    dataIndex: "ReceiverPhone",
    header: "Phone",
  },
  {
    id: "ReceiverFullAddress",
    header: "Address",
    render: (value, obj) =>
      `${obj.ReceiverAddr}, ${obj.ReceiverCity || ""}, ${obj.ReceiverProvince || ""}, ${obj.ReceiverPostCode || ""}`,
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Notes",
  },
  {
    id: "ShipmentOrderId",
    dataIndex: "ShipmentOrderId",
    header: "Shipment Order",
  },
  {
    id: "Status",
    dataIndex: "Status",
    header: "Status",
    render: (value, row) => {
      switch (row.id_Status) {
        case -1:
        case 99:
          return <Chip label={value} variant="filled" color="error" size="small" />;
        case 1:
          return <Chip label={value} variant="outlined" color="info" size="small" />;
        case 10:
          return <Chip label={value} variant="filled" color="info" size="small" />;
        case 20: //Dispatched style={{ backgroundColor: "#ffffe0" }}
          return <Chip label={value} variant="filled" size="small" color="default" />;
        case 30: //In Transit
          return <Chip label={value} variant="filled" size="small" style={{ backgroundColor: "#ffff00" }} />;
        case 80: //Out For Deliver style={{ backgroundColor: "#ffd700" }}
          return <Chip label={value} variant="filled" size="small" color="warning" />;
        case 88: //Delivered
          return <Chip label={value} variant="filled" color="success" size="small" />;
        default:
          return <Chip label={value} variant="outlined" color="info" size="small" />;
      }
    },
  },
];

export const DEFAULT_COLUMN_SETTING_OUT_REQ: string[] = [
  "InitTime",
  "SalesOrderId",
  "Status",
  "ReceiverName",
  "ReceiverPhone",
  "ReceiverFullAddress",
  "Notes",
  "ShipmentOrderId",
];

export const AVAILABLE_SUBTABLE_COLUMN_OUT_REQ: TableColumn[] = [
  {
    id: "ProductName",
    dataIndex: "Name",
    header: "Product",
  },
  {
    id: "Brand",
    dataIndex: "Brand",
    header: "Brand",
  },
  {
    id: "SKU",
    dataIndex: "SKU",
    header: "SKU",
  },
  {
    id: "Barcode",
    dataIndex: "Barcode",
    header: "Barcode",
  },
  {
    id: "Scale",
    dataIndex: "Scale",
    header: "Scale",
  },
  {
    id: "BoxType",
    dataIndex: "BoxType",
    header: "Box Type",
  },
  {
    id: "BoxTypeQty",
    dataIndex: "BoxTypeQty",
    header: "Quantity",
  },
  {
    id: "Qty",
    dataIndex: "Qty",
    header: "Piece",
  },
  {
    id: "Notes",
    dataIndex: "Notes",
    header: "Detail Notes",
  },
];

export const DEFAULT_SUBTABLE_COLUMN_SETTING_OUT_REQ: string[] = ["ProductName", "Brand", "Scale", "Qty", "Notes"];
